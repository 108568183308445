<template>
	<h1>
		{{ titlefy(entityType) }}
		<em class="bump">{{ prettyNr(total, $imperial) }}</em>
	</h1>

	<BaseLoadingText v-if="loading" />

	<template v-else>
		<BasePagination :alphabet="true" />

		<BasePagination v-if="pageCount > 1" :pageCount="pageCount" :currentPage="page" />

		<EntityList
			:entityType="entityType"
			:entities="entities"
			:dim="dim"
			:letter="letter"
			:page="page"
			:total="total"
			:letterTotal="letterTotal"
		/>

		<BasePagination :alphabet="true" />

		<BasePagination v-if="pageCount > 1" :pageCount="pageCount" :currentPage="page" />
	</template>
</template>

<script>
// Stores
import { useApiStore } from '@/stores/ApiStore'

// Views
import EntityList from '@/views/Admin/Index/EntityList'

// Components
import BaseLoadingText from '@/components/BaseLoadingText'
import BasePagination from '@/components/BasePagination'

// Internal
import { prettyNr, titlefy } from '@/use/StringMagic'
import { injectMetaData, updateTitle } from '@/use/MetaData'

export default {
	name: 'AdminIndexEntity',
	components: {
		BaseLoadingText,
		BasePagination,
		EntityList,
	},
	props: {
		// (Plural)
		entityType: {
			type: String,
			required: true,
		},
		letter: {
			type: String,
			default: 'a',
		},
		page: {
			type: [Number, String],
			default: 1,
		},
	},
	setup(props) {
		injectMetaData({
			title: `Admin: Index - ${titlefy(props.entityType)}`,
		})

		const apiStore = useApiStore()
		const adminIndexApi = apiStore.loadApi('adminIndex')

		return { adminIndexApi, prettyNr, titlefy }
	},
	data() {
		return {
			total: '',
			letterTotal: '',
			entities: [],
			pageCount: 0,
			loading: true, // Handles first time loading
			dim: false, // Handles loading when changing page
		}
	},
	computed: {
		entityType_page_letter() {
			return this.entityType + '_' + this.page + '_' + this.letter
		},
	},
	watch: {
		entityType_page_letter() {
			this.loadList(1)
		},
		$route(to) {
			updateTitle(`Admin: Index - ${titlefy(to.params.entityType)}`)
		},
	},
	mounted() {
		this.loadList()
	},
	beforeRouteUpdate(to, from, next) {
		if (!to.params.page) {
			// Complete URL if missing letter and/or page
			next({
				name: to.name,
				params: {
					entityType: to.params.entityType,
					letter: to.params.letter ? to.params.letter : 'a',
					page: 1,
				},
			})
		} else {
			next()
		}
	},
	methods: {
		// Load list of entities
		async loadList() {
			this.dim = true
			const { data } = await this.adminIndexApi.getIndex(this.entityType, this.letter, this.page)
			const { entities, total, letterTotal, pageCount } = data
			this.entities = entities
			this.total = total
			this.letterTotal = letterTotal
			this.pageCount = pageCount
			this.loading = false
			this.dim = false
		},
	},
}
</script>

<style lang="scss" scoped></style>
