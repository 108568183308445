<template>
	<div class="display small soft">
		Showing {{ prettyNr(entities.length, $imperial) }} of {{ prettyNr(letterTotal, $imperial) }} results
	</div>

	<div :class="classDim" class="links-wrap">
		<div class="inline-wrap" v-for="(entity, i) in entities" :key="i">
			<div class="link">
				<router-link
					:to="{
						name: 'AdminInspectEntity',
						params: {
							entityType: singular(entityType),
							id_username_namePath: entity.id,
						},
					}"
					><span>{{ entity.name || entity.title }}</span></router-link
				>
				<router-link :to="publicLinks[i]" class="small">{{
					entity.artworks
						? prettyNr(entity.artworks.length, $imperial)
						: entity.collectors
						? prettyNr(entity.collectors.length, $imperial)
						: 'page'
				}}</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { prettyNr, singular } from '@/use/StringMagic'

export default {
	name: 'AdminIndexEntityList',
	props: {
		entityType: String, // (Plural)
		entities: Array,
		dim: Boolean,
		letter: String,
		page: Number,
		total: Number,
		letterTotal: Number,
	},
	setup() {
		return { prettyNr, singular }
	},
	computed: {
		classDim() {
			return this.dim ? 'dim' : ''
		},
		// Returns public link of entity
		publicLinks() {
			return this.entities.map(entity => {
				if (this.entityType == 'artists') {
					return { name: 'Artist', params: { namePath: entity.namePath, category: entity.category } }
				} else if (this.entityType == 'artworks') {
					return {
						name: 'Artwork',
						params: {
							category: entity.category,
							namePath: entity.artistNamePath,
							titlePath: entity.titlePath,
						},
					}
				} else if (this.entityType == 'users') {
					return { name: 'User', params: { username: entity.username } }
				} else if (this.entityType == 'rooms') {
					return { name: 'Room', params: { id: entity.id } }
				} else if (this.entityType == 'lists') {
					// console.log(33, entity)
					// return {}
					return { name: 'UserList', params: { username: entity.owner.username, namePath: entity.namePath } } // Not tested
				}
				return {}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
/* Display */
.display {
	margin-bottom: 0.3rem;
	position: relative;
}

/* Wrap */
.links-wrap {
	column-count: 10;
	column-count: 4;
	column-gap: 0.5rem;
	margin-bottom: 0.4rem;
}

/* Links */
.links-wrap .inline-wrap {
	// Needed for colums to behave
	display: inline-block;
	width: 100%;
	position: relative;
}
.links-wrap .link {
	display: flex;
	height: 0.24rem;
	width: 100%;
	position: relative;
}
.links-wrap .link a {
	line-height: 0.24rem;
	height: 0.24rem;
}
.links-wrap .link a:first-child {
	flex: 1 1;
	position: relative;
}
.links-wrap .link a:first-child span {
	// Truncate
	position: absolute;
	top: 0;
	left: 0;
	right: 0.1rem;
	bottom: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.links-wrap .link a:last-child {
	flex: 0 1;
	opacity: 0.5;
	text-align: right;
}

/**
 * Responsive
 */

/* .links-wrap = vw - 160 - 80 - x * 50 / x */
@media only screen and (max-width: calc(1950px + 340px)) {
	.links-wrap {
		column-count: 9;
	}
}
@media only screen and (max-width: calc(1750px + 340px)) {
	.links-wrap {
		column-count: 8;
	}
}
@media only screen and (max-width: calc(1550px + 340px)) {
	.links-wrap {
		column-count: 7;
	}
}
@media only screen and (max-width: calc(1350px + 340px)) {
	.links-wrap {
		column-count: 6;
	}
}
@media only screen and (max-width: calc(1150px + 340px)) {
	.links-wrap {
		column-count: 5;
	}
}
@media only screen and (max-width: calc(950px + 340px)) {
	.links-wrap {
		column-count: 4;
	}
}
@media only screen and (max-width: calc(750px + 340px)) {
	.links-wrap {
		column-count: 3;
	}
}
@media only screen and (max-width: calc(550px + 340px)) {
	.links-wrap {
		column-count: 2;
	}
}
@media only screen and (max-width: calc(350px + 340px)) {
	.links-wrap {
		column-count: 1;
	}
}
</style>
